<template>
  <a-form :layout="formLayout" class="adherent_form" @submit="handleSubmit" :form="form">
    <a-row>
      <a-col :span="18">
        <a-form-item>
          <p class="section_text">
            <a-icon type="user-add" class="user_icon"/>Utilisateur
          </p>
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="Nom utilisateur">
          <a-input
            placeholder="Nom utilisateur"
            v-decorator="[
                                           'nom_utilisateur',
                                           {
                                             rules: [{
                                               required: true, min : 6,max:20, message: 'Votre nom d\'utilisateur doit comprendre entre 6 et 20 caractères !',
                                             }]
                                           }
                                         ]"
          />
        </a-form-item>
        <a-input type="hidden" id="userId" name="userId" v-model="userId"/>
        <a-form-item v-bind="formItemLayout" label="Nom & Prénom">
          <a-input
            placeholder="Nom & Prénom"
            v-decorator="[
                                           'nom_prenom',
                                           {
                                             rules: [{
                                               required: true,min : 6,max:40, message: 'Votre Nom & Prénoms doit comprendre entre 6 et 40 caractères!',
                                             }]
                                           }
                                         ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="Mot de passe">
          <a-input
            placeholder="Mot de passe"
            type="password"
            v-decorator="[
          'password',
          {
               rules: [
                       {required: true, message:'Mot de passe est obligatoire!'}, 
                       {pattern: /(?=.*\d.*)(?=.*[a-zA-Z].*).{8,20}/,
                        message:' Utilisez au moins 8 caractères avec des lettres, des chiffres pour votre mot de passe !'},
                       {validator: this.validateToNextPassword}
                       ]
          } ]"
          />
        </a-form-item>
        <a-form-item v-bind="formItemLayout" label="Confirmer mot passe">
          <a-input
            placeholder="Confirmer mot passe"
            v-decorator="[
                           'confirm',
                           {
                             rules: [{
                               required: true, message: 'Il faut confimer votre mot de passe!',
                             }, {
                               validator: compareToFirstPassword,
                             }],
                           }
                         ]"
            type="password"
            @blur="handleConfirmBlur"
          />
        </a-form-item>

        <a-form-item v-bind="formItemLayout" label="Profil">
          <a-select
            v-decorator="[
        'profil',
        {rules: [{ required: false, message: 'Profil est obligatoire!' }]}
      ]"
            placeholder="Choisisie un profil"
          >
            <a-select-option value="13">Super Admin</a-select-option>
            <a-select-option value="16">Admin</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row>
      <a-col :span="2" :offset="4">
        <a-form-item v-bind="tailFormItemLayout">
          <a-button type="primary" htmlType="submit">Confimer</a-button>
        </a-form-item>
      </a-col>
      <a-col :span="2" :offset="1">
        <a-form-item v-bind="tailFormItemLayout">
          <a-button type="default" @click="cancel">Annuler</a-button>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

const UTILISATEURS_URl = "/admin/utilisateurs";
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 10 }
  }
};
export default {
  name: "UtilisateursEditor",
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  mounted() {
    if (this.editMode) {
      let user = this.$store.getters.getUtilisateurById(
        this.$route.params["id"]
      );
      this.form.setFieldsValue({
        nom_prenom: user.fullName,
        nom_utilisateur: user.userName,
        password: this.defaultPassword,
        confirm: this.defaultPassword
      });
      this.userId = user.userId;
    }
  },
  data() {
    return {
      userId: "",
      confirmDirty: false,
      defaultPassword: "7A0c1060",
      formItemLayout: formItemLayout,
      tailFormItemLayout: tailFormItemLayout
    };
  },
  computed: {
    ...mapState({ dataSource: state => state.utilisateurs.utilisateurs })
  },
  methods: {
    cancel() {
      this.$router.push(UTILISATEURS_URl);
    },
    handleSubmit(e) {
      e.preventDefault();
      let _this = this ;
      this.form.validateFieldsAndScroll((err, values) => {
        if (!err) {
          const user = {};
          user.userId = this.userId;
          user.userName = values.nom_utilisateur;
          user.fullName = values.nom_prenom;
          if (values.password === _this.defaultPassword) {
            user.passworld = "00000000";
            user.passworld_confirm = "00000000";
          } else {
            user.passworld = values.password;
            user.passworld_confirm = values.password;
          }
          if (this.editMode) {
            this.update(user);
          } else {
            this.add(user);
          }
          this.$router.push(UTILISATEURS_URl);
        }
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
    compareToFirstPassword(rule, value, callback) {
      const form = this.form;
      if (value && value !== form.getFieldValue("password")) {
        callback(
          "Les mots de passes ne correspondent pas veuillez réessayer !"
        );
      } else {
        callback();
      }
    },
    validateToNextPassword(rule, value, callback) {
      const form = this.form;
      if (value && this.confirmDirty) {
        form.validateFields(["confirm"], { force: true });
      }
      callback();
    },

    getUserbyId(id) {
      return this.dataSource.find(utilisateur => utilisateur.userId == id);
    },
    ...mapActions({
      update: "updateUtilisateur",
      add: "storeUtilisateur"
    }),
    ...mapGetters({
      getUser: "utilisateurById"
    })
  }
};
</script>
<style scoped>
.section_text {
  font-size: 1.6em;
  font-weight: bolder;
}

.adherent_form {
  height: auto;
}
.user_icon {
  font-size: 1.8em;
}
</style>